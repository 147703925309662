$theme-colors: (
	"primary": #1870ca,
	"secondary": #40c7e9,
);

@import "~bootstrap/scss/bootstrap";
@import "~rfs/scss";
@import "../hamburgers/hamburgers";

@import "./uno-variable";
@import "./uno-mixin";
@import "./uno-custom";

#wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
	overflow-x: hidden;
}

#header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: $zindex-fixed;
	padding: 1rem 0;
	background-color: transparent;
	transition: background 0.5s linear;

	.header {
		&-icon {
			color: colors(3);
		}
	}

	&.sticky {
		background: colors(3);
		transition: background 0.3s linear;
		box-shadow: 1px 1px 1rem 0px rgba(colors(5), $o01);

		.header {
			&-icon {
				color: colors(5);
			}
		}
	}
}

#logo {
	img {
		width: auto;
		max-height: 71px;

		@include media-breakpoint-down(md) {
			max-height: 50px;
		}
	}

	.logo {
		&-fixed {
			display: block;
		}

		&-sticky {
			display: none;
		}
	}

	&.sticky {
		.logo {
			&-fixed {
				display: none;
			}

			&-sticky {
				display: block;
			}
		}
	}
}

#navigation {
	color: colors(3);
	text-align: right;
	@include transition(0.25s all);
	border-bottom: 2px solid rgba(colors(3), $o03);

	&.sticky {
		color: colors(5);
		border-color: colors(9);
	}

	ul {
		display: inline-flex;
		align-items: center;

		li {
			display: inline-block;
			position: relative;
			margin: 0 1.5rem 0 0;
			padding: 1.5rem 0;
			@include transition(0.25s all);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.active {
				> a {
					@extend .font-bold;
					color: colors(1);
				}
			}

			&:hover {
				> a {
					color: colors(1);
				}

				> ul {
					opacity: 1;
					visibility: visible;
					transform: translate(-50%, 0);
					@include transition(0.25s all);
				}
			}

			a {
				@extend .font-bold;
				font-size: rem(14);
				color: inherit;
				text-decoration: none;
				text-transform: capitalize;
				letter-spacing: 1px;
			}

			&.search {
				a {
					color: colors(1);
				}
			}
		}

		ul {
			position: absolute;
			top: 100%;
			left: 50%;
			z-index: $zindex-popover;
			visibility: hidden;
			opacity: 0;
			border-top: 2px solid colors(1);
			transform: translate(-50%, 0.5rem);
			@include transition(0.25s all);

			display: block;
			min-width: 200px;
			background: colors(5);
			padding: 1rem 0;
			text-align: center;

			li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: block;
				position: unset;

				&:hover {
					> a {
						color: colors(1);
						text-decoration: none;
					}
				}

				a {
					display: block;
					position: relative;
					color: colors(3);
					@extend .font-regular;
					padding: 0.5rem 1rem;
					line-height: 1.2;
				}

				ul {
					top: 0;
					left: 150%;
					margin-left: 2px;
				}
			}
		}
	}
}

#searchModal {
	.modal-content {
		background: transparent;

		#keyword {
			border-top-left-radius: 2rem;
			border-bottom-left-radius: 2rem;
			padding: 1.5rem 2rem 1.5rem;
		}

		.btn {
			border-top-right-radius: 2rem;
			border-bottom-right-radius: 2rem;
		}
	}
}

#banner {
	position: relative;
	z-index: $zindex-sticky;
	background: colors(5);

	.banner {
		&-slide {
			position: relative;
			display: flex;
			align-items: center;
			height: 100vh;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o03);

			@include media-breakpoint-down(xs) {
				// align-items: flex-end;
			}
		}

		&-overlay {
			// position: absolute;
			// left: 0;
			// right: 0;
			// top: 0;
			// height: 100%;
			// width: 100%;
			// background: linear-gradient(
			// 	0deg,
			// 	rgba(colors(3), 1) 0%,
			// 	rgba(colors(7), 0) 50%,
			// 	rgba(colors(7), 0) 80%,
			// 	rgba(colors(3), 1) 100%
			// );
			// z-index: 0;
		}

		&-content {
			position: relative;
			max-width: 50%;
			z-index: 2;
			text-shadow: -1px 1px 5px rgba(colors(5), $o04);

			@include media-breakpoint-down(lg) {
				max-width: 70%;
			}

			@include media-breakpoint-down(md) {
				max-width: 80%;
			}

			@include media-breakpoint-down(sm) {
				max-width: 88%;
			}

			@include media-breakpoint-down(xs) {
				max-width: 100%;
			}
		}

		&-dots {
			display: none;
		}

		&-nav {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;

			.swiper-button-prev,
			.swiper-button-next {
				right: 1rem;
				left: auto;
				background-image: none;
				background: rgba(colors(3), $o03);
				color: colors(6);
				height: 40px;
				width: 40px;
				border-radius: 100%;
				border: 1px solid colors(3);
				text-align: center;
				margin-top: 0px;
				transform: translateY(-50%);
				line-height: 38px;

				&:hover {
					background: colors(1);
					border-color: colors(1);
				}
			}

			.swiper-button-next {
				top: calc(50% - 1.8rem);
			}

			.swiper-button-prev {
				top: calc(50% + 1.8rem);
			}

			@include media-breakpoint-down(xs) {
				display: none;
			}
		}

		&-scroll {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 1rem;
			z-index: 3;
			text-align: center;

			&-button {
				display: inline-block;
				color: colors(3);

				&:hover,
				&:active,
				&:focus {
					color: colors(1);
					text-decoration: none;

					.scroll-downs {
						.mousey {
							border-color: colors(1);

							.scroller {
								background-color: colors(1);
							}
						}
					}
				}

				.scroll-downs {
					margin-bottom: 0.5rem;

					.mousey {
						position: relative;
						width: 2px;
						padding: 5px 10px;
						height: 30px;
						border: 2px solid colors(3);
						border-radius: 25px;
						opacity: 0.75;
						box-sizing: content-box;
						cursor: pointer;
						transition-duration: 500ms;

						.scroller {
							width: 2px;
							height: 5px;
							border-radius: 25%;
							background-color: colors(3);
							animation-name: scrollMouse;
							animation-duration: 2.2s;
							animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
							animation-iteration-count: infinite;
						}
					}
				}
			}
		}
	}
}

#innerBanner {
	position: relative;
	background-color: colors(5);
	z-index: $zindex-sticky;

	.inner-banner {
		&-slide {
			height: 400px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o04);
		}

		&-title {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			z-index: 1;
		}

		&-breadcrumb {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background-color: rgba(colors(5), $o04);

			.breadcrumb {
				padding: 0.5rem 0;
				color: colors(3);
			}
		}

		@include media-breakpoint-down(lg) {
		}

		@include media-breakpoint-down(sm) {
			&-slide {
				height: 400px;
			}
		}
	}
}

#breadcrumb {
	text-align: center;

	.breadcrumb {
		margin: 0;
		background: none;
		padding: 1rem 0;
		color: colors(5);
		font-size: rem(10);
		letter-spacing: $l01;
		text-transform: capitalize;
		border-radius: 0;
		align-items: center;

		> li {
			position: relative;
			padding: 0.5rem 0;
			line-height: 1;
			text-overflow: ellipsis;
			overflow: hidden;

			+ li {
				padding-left: 2rem;

				&:before {
					position: absolute;
					left: 0.7rem;
					top: 20%;
					content: ".";
					color: inherit;
					font-size: rem(25);
					margin: 0;
					padding: 0;
					line-height: 0;
				}
			}

			// &:last-child {
			//   display: none;
			// }
		}
	}

	a {
		color: inherit;
		font-family: inherit;
		text-decoration: none;
		text-transform: capitalize;

		&:hover {
			color: colors(1);
			text-decoration: none;
		}
	}
}

#content {
	position: relative;
	flex-grow: 1;
}

#innerPage {
	position: relative;
	z-index: $zindex-sticky;
	padding: 2rem 0;

	.inner {
		&-main-services {
			@extend .full-container;
			margin-top: -2rem;
			margin-bottom: 3rem;
			padding: 3rem 0;
			background-color: colors(6);

			.card {
				height: 100%;

				&-img-top {
					border-radius: 0;
					height: 250px;
					object-fit: cover;
				}

				&-body {
					display: flex;
					flex-direction: column;

					p {
						flex-grow: 1;
					}
				}
			}
		}
	}
}

#home {
	position: relative;

	#homeAnchor {
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		padding-top: $headerHeight;
		z-index: -1;

		@include media-breakpoint-down(md) {
			padding-top: $headerHeightMobile;
		}
	}

	.home {
		&-services {
			padding: 2rem 0;

			&-box {
				display: block;
				position: relative;
				overflow: hidden;
				text-decoration: none;
				color: colors(3);
				height: 350px;

				&:active,
				&:hover,
				&:focus {
					text-decoration: none;
					color: colors(1);
				}

				a {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 100%;
					z-index: 2;
					opacity: 0;
				}

				img {
					object-fit: cover;
					max-width: unset;
					width: 100%;
					height: 100%;
				}

				p {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;

					@extend .font-bold;
					@extend .text-xl;
					@extend .gradient-dark-bottom;

					margin: 0;
					padding: 4rem 3rem 1rem 1rem;
					z-index: 1;
					line-height: 1.2;
				}
			}
		}

		&-footprint {
			margin-top: -7rem;
			padding: 5rem 0 3rem;
			background-color: colors(6);
		}

		&-other {
			padding: 2rem 0 4rem;
		}

		&-career {
			display: block;
			position: relative;
			overflow: hidden;
			text-decoration: none;
			color: colors(3);
			height: 400px;

			&:active,
			&:hover,
			&:focus {
				text-decoration: none;
				color: colors(1);
			}

			a {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 100%;
				z-index: 2;
				opacity: 0;
			}

			img {
				object-fit: cover;
				max-width: unset;
				width: 100%;
				height: 100%;
			}

			&-content {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;

				@extend .gradient-dark-bottom;

				padding: 5rem 1.5rem 1.5rem 1.5rem;
				z-index: 1;

				button {
					display: block;
					margin-left: auto;
					color: colors(3);
					background: rgba(colors(3), $o03);
					text-decoration: none;
					text-align: center;
					width: 2.5rem;
					height: 2.5rem;
					border: 1px solid colors(3);
					border-radius: 100%;

					.fa,
					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: rem(14);
					}

					&:hover,
					&:focus,
					&:active {
						color: colors(3);
						background: colors(1);
						border-color: colors(1);
					}
				}
			}
		}

		&-news {
			display: block;
			padding: 1rem 0;
			border-bottom: 1px solid colors(9);
			color: colors(7);
			font-size: rem(14);
			letter-spacing: 1px;
			text-decoration: none;

			svg,
			.fa {
				opacity: 0;
			}

			&:active,
			&:hover,
			&:focus {
				text-decoration: none;
				color: colors(1);

				svg,
				.fa {
					opacity: 1;
				}
			}
		}
	}
}

#ads {
	// max-height: 200px;
	img {
		max-height: 200px;
	}
}

#backToTop {
	position: absolute;
	z-index: 3;
	top: -1.25rem;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	color: colors(7);
	background: colors(3);
	text-decoration: none;
	text-align: center;
	width: 2.5rem;
	height: 2.5rem;
	border: 1px solid colors(7);
	border-radius: 100%;

	.fa,
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(14);
	}

	&:hover,
	&:focus,
	&:active {
		color: colors(3);
		background: colors(2);
		border-color: colors(3);
	}
}

#footer {
	position: relative;

	.footer {
		&-menu {
			div {
				> a[href] {
					&:active,
					&:hover,
					&:focus {
						&:before {
							content: "-";
							margin-right: 0.5rem;
						}
					}
				}
			}
		}

		&-copyright {
			letter-spacing: 1px;
			background: rgba(colors(2), 1);
			background: linear-gradient(0deg, rgba(colors(2), 1) 0%, rgba(colors(1), 1) 100%);
		}
	}
}

#newsletter {
	.newsletter {
		&-field {
			@extend .font-italic;
			@extend .text-xs;
			color: colors(4);
			padding: 1rem 2rem;
			border-color: colors(9);
			border-top-left-radius: 2rem;
			border-bottom-left-radius: 2rem;
			letter-spacing: 1.5px;

			&::placeholder {
				color: colors(7);
			}
		}

		&-button {
			padding-left: 2rem;
			padding-right: 2rem;
			border-top-right-radius: 2rem;
			border-bottom-right-radius: 2rem;
		}
	}
}

#socialMedia {
	display: flex;
	flex-wrap: wrap;
	margin-right: -0.5rem;
	margin-left: -0.5rem;
	align-items: center;

	.social-media {
		&-link {
			flex: 0 0 auto;
			max-width: 100%;
			position: relative;
			display: block;
			color: colors(7);
			background: transparent;
			text-decoration: none;
			text-align: center;
			width: 2.5rem;
			height: 2.5rem;
			margin: 0 0.5rem;
			border: 1px solid colors(7);
			border-radius: 100%;

			.fa,
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: rem(14);
			}

			&:hover,
			&:focus,
			&:active {
				color: colors(3) !important;
				background: colors(1);
				border-color: colors(3);
			}
		}
	}
}

#sitemap {
	.sitemap {
		&-title {
			display: inline-block;
			margin-bottom: 2rem;

			~ ul {
				list-style: none;
				padding: 0;
			}
		}
	}

	ul {
		padding: 0.2rem 1rem;

		li {
			display: block;
			color: colors(5);
			text-transform: uppercase;

			a {
				display: inline-block;
				font-family: inherit;
				font-size: inherit;
				color: inherit;
				text-decoration: none;
				padding: 0.2rem 0;

				&:hover,
				&:focus,
				&:active {
					color: colors(2);
					text-decoration: none;
				}
			}
		}
	}
}

#mobileMenu {
	@extend .font-bold;
	color: colors(5);
	text-transform: uppercase;

	&:not(.mm) {
		display: none;
	}

	&.mm {
		// background: colors(3);

		li:before {
			border-color: colors(2);
			opacity: 1;
		}

		&:after {
			box-shadow: 0 1px 0 0 rgba(colors(5), .625), 0 1px 0 0 rgba(colors(5), $o01);
			color: colors(2);
			opacity: 1;
		}
	  }

	a {
		@extend .font-bold;
		text-transform: none;
		text-decoration: none;

		&:hover,
		&:active,
		&:focus {
			color: colors(2);
			text-decoration: none;
		}
	}

	.search {
		display: none;
	}
}

#gallery {
	img {
		width: 100%;
		height: 250px;
		object-fit: cover;
	}
}

#news {
	img {
		width: 100%;
		height: 250px;
		object-fit: cover;
	}
}
