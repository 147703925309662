img {
	height: auto;
	max-width: 100%;

	&.full-width {
		max-width: 100vw;
		width: 100%;
	}
}

.is-empty {
	&:empty {
		display: none;
	}
}

.none,
.hidden {
	display: none;
}

.container {
	// @media (min-width: 1440px) {
	//   max-width: 1366px;
	// }

	// @include media-breakpoint-down(xl) {
	//   max-width: 100%;
	// }
}

.row {
	&.small-gutters {
		margin-right: -0.5rem;
		margin-left: -0.5rem;

		> .col,
		> [class*="col-"] {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
		}
	}
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;

	&-track {
		background: colors(9);
	}

	&-thumb {
		background: colors(2);

		&:hover {
			background: colors(1);
		}
	}
}

:focus {
	// outline-color: transparent;
}

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
	// width: 100%;
	// height: auto;

	font-family: $baseFont;
	font-size: $baseSize;
	color: colors(5);

	scroll-behavior: smooth;
	scrollbar-color: colors(2) colors(9);
	scrollbar-width: thin;

	@include media-breakpoint-up(sm) {
		padding-right: 0 !important;
	}
}

iframe {
	max-width: 100%;
}

a {
	position: relative;
	color: colors(2);
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: colors(4);
		text-decoration: underline;
	}
}

.full-container {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	overflow-x: hidden;
}

.modal {
	-webkit-overflow-scrolling: touch;
	background: rgba(colors(5), $o05);

	&-header {
		line-height: 1;
	}

	&-content {
		@extend .shadow;
		border-color: colors(6);
		border-radius: 0.5rem;

		.close {
			position: absolute;
			right: -1rem;
			top: -1rem;
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
			color: colors(3);
			background: colors(2);
			text-align: center;
			opacity: 1 !important;
			z-index: 2;
			font-size: rem(15);

			&:hover {
				background: colors(1);
			}
		}
	}

	&-body {
		border-radius: 0.5rem;
	}

	@include media-breakpoint-up(sm) {
		padding-right: 0 !important;
	}

	@include media-breakpoint-down(xs) {
		&-content {
			.close {
				right: -0.5rem;
				top: -0.5rem;
			}
		}
	}
}

.bg-img {
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&.attach {
		background-attachment: fixed;
	}

	&.contain {
		background-size: contain !important;
	}

	&.auto {
		background-size: auto !important;
	}

	&.top {
		background-position: center top !important;
	}

	&.bottom {
		background-position: center bottom !important;
	}

	&.start {
		background-position: left center !important;
	}

	&.end {
		background-position: right center !important;
	}
}

.form-group {
	position: relative;

	&-icon {
		position: absolute;
		top: 1rem;
		left: 1.2rem;
		color: colors(2);

		+ input,
		+ textarea {
			padding-left: 2.6rem !important;
		}
	}

	label {
		&:not(.is-invalid):not(.custom-control-label) {
			@extend .font-bold;
			@extend .text-lg;
		}

		&.is-invalid {
			color: colors(10);
			margin-bottom: 0;
		}
	}

	&.invalid-feedback {
		color: colors(10);
		@extend .font-bold;
		@extend .text-base;
	}
}

.form-control {
	&:hover,
	&:active,
	&:focus {
		// box-shadow: 0 0 0 0.2rem transparent;
	}

	&[type="date"],
	&[type="text"],
	&[type="number"],
	&[type="password"],
	&[type="email"],
	&[type="file"],
	&[type="tel"] {
		padding: 0.6rem 0.8rem 0.8rem;
		height: auto;
		min-width: 5rem;

		&::placeholder {
			font-family: inherit;
			font-size: inherit;
			text-transform: capitalize;
		}
	}

	&.custom-select {
		padding: 0.6rem 0.8rem 0.8rem;
		height: auto;

		&::placeholder {
			font-family: inherit;
			font-size: inherit;
			text-transform: capitalize;
		}

		> option[value=""] {
			text-transform: capitalize;
		}
	}

	&.is-invalid {
		&:not(.custom-select) {
			border-color: colors(10) !important;
			padding-right: calc(1.5em + 0.75rem) !important;
			background-image: $svg-invalid !important;
		}

		&.custom-select {
			border-color: colors(10) !important;
		}
	}
}

textarea.form-control {
	padding: 0.6rem 0.8rem 0.8rem;
	height: auto;

	&::placeholder {
		font-family: inherit;
		font-size: inherit;
		text-transform: capitalize;
	}
}

.custom-control {
	&.custom-radio {
		> label {
			&.is-invalid {
				&:not(.custom-control-label) {
					position: absolute;
					bottom: 0.5rem;
				}

				~ label {
					&.custom-control-label {
						padding-bottom: 2rem;
					}
				}
			}
		}
	}

	&-input {
		&:checked {
			~ .custom-control {
				&-label {
					&::before {
						// border-color: colors(1);
						// background-color: colors(1);
					}
				}
			}
		}
	}
}

.line-height-reset {
	line-height: 1 !important;
}

.spacing {
	&-normal {
		letter-spacing: 0;
	}

	&-wide {
		letter-spacing: 0.025em;
	}

	&-wider {
		letter-spacing: 0.05em;
	}

	&-widest {
		letter-spacing: 0.1em;
	}
}

.btn {
	@extend .font-bold;
	font-size: rem(14);
	padding: 0.8rem 1.5rem 1rem;
	text-decoration: none;
	line-height: 1;
	letter-spacing: 1px;
	text-transform: capitalize;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		// box-shadow: 0 0 0 0.2rem transparent;
	}

	&-primary {
		&:hover,
		&:active,
		&:focus {
			border-color: colors(1);
			background-color: colors(1);
		}
	}

	&-sm {
		padding: 0.4rem 1rem 0.6rem;
	}

	&-lg {
		padding: 1rem 2.5rem 1.2rem;
	}

	&-round {
		border-radius: 50rem;
	}

	&-flat {
		border-radius: 0;
	}

	&-single {
		padding: 0.7rem 1rem;

		&.btn-lg {
			padding: 1rem 1.3rem;
		}
	}

	&-icon,
	&-plus,
	&-back {
		padding-left: 3rem;
		position: relative;

		&::after {
			content: "";
			@extend %fal;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 1.5rem;
		}

		&.btn-sm {
			padding-left: 2rem;

			&::after {
				left: 0.5rem;
			}
		}

		&.btn-lg {
			padding-left: 3.5rem;

			&::after {
				left: 2rem;
			}
		}

		&.btn-bold {
			&::after {
				@extend %fas;
			}
		}
	}

	&-icon {
		&::after {
			content: $fa24;
		}
	}

	&-plus {
		&::after {
			content: $fa24;
		}
	}

	&-back {
		&::after {
			content: $fa02;
		}
	}
}

.hover {
	&-primary {
		&:hover {
			color: colors(2);
		}
	}

	&-secondary {
		&:hover {
			color: colors(1);
		}
	}
}

// .big-title {
//   position: relative;
//   color: colors(1);
//   @extend .txt-f04;
//   text-transform: capitalize;
//   letter-spacing: $l01;
//   margin-top: 0;
//   margin-bottom: 2rem;

//   &--center {
//     text-align: center;
//   }

//   &--right {
//     text-align: right;
//   }
// }

.small-title {
  position: relative;
  color: colors(5);
  @extend .font-bold;
  @extend .text-sm;
  letter-spacing: $l01;
  text-transform: uppercase;
  padding-left: 5rem;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    width: 4rem;
    height: 2px;
    background: colors(1);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:active,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.invalid-g-recaptcha {
	display: none;
}

.g-recaptcha {
	&.captcha-center {
		> div {
			margin: 0 auto;
		}
	}

	@include media-breakpoint-down(xs) {
		> div {
			margin: 0 auto;
		}
	}

	@media (max-width: 375px) {
		transform: scale(0.77);
		transform-origin: 0 0;
	}
}

.fa {
	&-vertical-text-bottom {
		vertical-align: text-bottom;
	}
}

#tinymce,
.mce-content-body {
	.row {
		margin: 0;
	}

	.color-c03 {
		color: black;
	}
}

.gradient {
	&-dark {
		&-bottom {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
		}
	}
}

.object-fit {
	&-cover {
		object-fit: cover;
	}

	&-contain {
		object-fit: contain;
	}
}